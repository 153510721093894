<template>
    <div v-if="condition">
        <div :class="['card card-recommendations card-salary', { in_modal: type === 'onboarding' }]">
            <div class="card-body">
                <div class="card-heading">
                    <div class="header">
                        <p class="title" v-html="$t(`recommendations.salary.card.title.${type === 'onboarding' ? 'worker' : 'work_provider'}`)"></p>
                        <p class="subtitle" v-html="$t(`recommendations.salary.card.subtitle.${type === 'onboarding' ? 'worker' : 'work_provider'}`)"></p>
                    </div>
                    
                    <span class="beta-label" v-html="$t('language.beta')"></span>
                </div>

                <div v-if="loaded">
                    <span v-if="job_class && job_type === 'class'" class="selected-sector" v-html="$t('recommendations.selected_job', { job: $t(`classes.${job_class}`) })"></span>
                    <span v-if="job_key && job_type === 'job'" class="selected-sector" v-html="$t('recommendations.selected_job', { job: $t(`jobs.${job_key}`) })"></span>
                    
                    <div class="insights">
                        <div v-for="(item, index) in platform_salaries" :key="index" class="insight"><span v-html="$t('recommendations.salary.card.salary_for_experience', { experience: $t(`recommendations.salary.card.experiences.${item.level}`) })"/><span class="percentage-with-tt" :content="$t('tooltips.recommendations.salaries.platform')" v-tippy><span :class="['percentage grey', { blur: valid_recommendations !== null && !valid_recommendations }]">{{`${item.worker.median} / ${item.offer.median} ${$t('recommendations.salary.card.by_hour')}`}}</span><TippyIndicator /></span></div>
                        <div v-if="market_salaries" class="insight">
                            <span v-html="$t('recommendations.salary.card.market_salary', { region: $t(`recommendations.salary.card.regions.${market_salaries.region }`) })"></span>
                            <span class="percentage-with-tt" :content="$t('tooltips.recommendations.salaries.market')" v-tippy><span class="percentage grey">{{`${market_salaries.value} ${$t('recommendations.salary.card.by_hour')}`}}</span><TippyIndicator /></span>
                        </div>

                        <div v-if="platform_salaries.length === 0 && !market_salaries" class="insight">
                            <span>{{ $t('recommendations.data_not_available') }}</span>
                        </div>
                    </div>

                    <span v-if="valid_recommendations !== null && !valid_recommendations" class="unlock">{{$t('recommendations.salary.card.unlock')}}</span>
                </div>

                <div v-else class="card loading"></div>

                <div class="footnotes row nopadding-left">
                    <p v-if="last_update" v-html="$t('recommendations.last_update', { time: 1 })"></p>
                    <p v-else v-html="$t('recommendations.last_udpate_not_available')"></p>
                    
                    <button id="recommendations-salary-modal-close" v-if="type === 'onboarding'" :class="['cta cta-slim cta-outline dark max-width']" @click="$emit('cancel')">{{$t('language.close')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import TippyIndicator from '../../../Utils/TippyIndicator.vue'

export default {
    name: 'SalaryCard',

    components: { TippyIndicator },

    props: {
        classification: {
            type: Object,
            default() {
                return null
            }
        },

        valid_recommendations: {
            type: Boolean,
            default() {
                return false
            }
        },

        id: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        }
    },

    data() {
        return {
            platform_salaries: [],
            market_salaries: null,
            last_update: null,

            loading_manager: {
                platform_salaries_loaded: true,
                market_salaries_loaded: false,
                last_update_loaded: false
            }
        }
    },

    watch: {
        job_class: function () {
            const self = this;
            if(self.job_class) {
                self.get_market_salaries();

                if(self.experience_levels.length !== 0) {
                    //self.get_platform_salaries();
                }
            }else {
                self.market_salaries = null;
            }
        },

        experience_levels: function () {
            const self = this;
            if(self.job_class && self.experience_levels && self.experience_levels.length !== 0) {
                self.get_market_salaries();
                //self.get_platform_salaries();
            }else {
                self.platform_salaries = [];
                self.market_salaries = null;
            }
        },

        valid_recommendations: function() {
            const self = this;
            if(self.job_class) {
                self.get_market_salaries();

                if(self.experience_levels.length !== 0) {
                    //self.get_platform_salaries();
                }
            }

            self.get_last_update();
        },
    },

    computed: {
        condition: function() {
            const self = this;
            if(self.type === 'onboarding') { return true }
            else { return self.sector && self.job_class && self.experience_levels.length !== 0 && self.valid_recommendations !== null }
        },

        loaded: function() {
            const self = this;
            return self.loading_manager.platform_salaries_loaded && self.loading_manager.market_salaries_loaded && self.loading_manager.last_update_loaded;
        },

        sector: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].sector;
            }

            return null;
        },

        job_class: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].code;
            }

            return null;
        },

        job_key: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].key
            }

            return null;
        },

        experience_levels: function() {
            const self = this;

            if(self.classification && self.classification.experienceLevels) {
                return self.classification.experienceLevels
            }

            return null;
        },

        job_type: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].type;
            }

            return null;
        },
    },

    methods: {
        get_platform_salaries: function() {
            const self = this;
            
            const code = self.job_class && self.job_class ? self.job_class : '00000';
            const experience_levels_string = !self.experience_levels[0] ? 'none' : self.experience_levels.join('_');
            
            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    'type': self.type,
                    'id': self.id
                }
            })

            instance.get(`salaries/platform/${code}/${experience_levels_string}`)
                .then(response => {
                    if(response.status == 200) {
                        self.platform_salaries = response.data;
                    }

                    self.loading_manager.platform_salaries_loaded = true;
                })
                .catch(error => {
                    self.loading_manager.platform_salaries_loaded = true;

                    if(error && error.response) {
                        if(error.response.status !== 400) {
                            console.log(JSON.stringify(error.response, null, 4))
                        }
                    } else { console.log(error); }
                });
        },

        get_market_salaries: function() {
            const self = this;
            
            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                }
            })
            
            instance.get(`salaries/market/${self.job_class && self.job_class ? self.job_class : '00000'}`)
                .then(response => {
                    if(response.status == 200) {
                        self.market_salaries = response.data;
                    }

                    self.loading_manager.market_salaries_loaded = true;
                })
                .catch(error => {
                    self.loading_manager.market_salaries_loaded = true;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                            console.log(JSON.stringify(error.response, null, 4))
                        }
                    } else { console.log(error); }
                });
        },

        get_last_update: function() {
            const self = this;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: {
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                }
            })

            instance.get('salaries/lastupdate')
                .then(response => {
                    if(response.status == 200) {
                        self.last_update = response.data.minutes;
                    }

                    self.loading_manager.last_update_loaded = true;
                })
                .catch(error => {
                    self.loading_manager.last_update_loaded = true;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        }
    },

    mounted() {
        const self = this;

        self.get_last_update();
        if(self.type === 'onboarding') { self.get_market_salaries();  }
        if(self.job_class) { self.get_market_salaries(); }
        //if(self.experience_levels && self.experience_levels.length !== 0) { self.get_platform_salaries(); }
    }
}
</script>
