<template>
    <div v-if="condition">
        <div :class="['card card-recommendations card-benefits', { in_modal: type === 'onboarding' }]">
            <div class="card-body">
                <div class="card-heading">
                    <div class="header">
                        <p class="title" v-html="$t(`recommendations.benefits.card.title.${type === 'onboarding' ? sector ? 'worker' : 'empty' : 'work_provider'}`)"></p>
                    </div>
                    
                    <span class="beta-label" v-html="$t('language.beta')"></span>
                </div>

                <span v-if="job_class && job_type === 'class'" class="selected-sector" v-html="$t('recommendations.selected_job', { job: $t(`classes.${job_class}`) })"></span>
                <span v-if="job_key && job_type === 'job'" class="selected-sector" v-html="$t('recommendations.selected_job', { job: $t(`jobs.${job_key}`) })"></span>

                <div v-if="loaded">
                    <div v-if="job_class || type === 'onboarding'" class="insights">
                        <!-- <div v-for="(item, index) in sector_benefits" :key="index" :class="['insight', { blur: !valid_recommendations && index !== 0 }]"><span v-html="`<b>${index + 1}.</b> ${$t(`benefits.long.${item.key}`)}`"/><span class="percentage-with-tt" :content="$t(`tooltips.recommendations.benefits.${type === 'onboarding' ? sector ? 'offer' : 'empty' : 'job'}`)" v-tippy><span class="percentage grey">{{`${item.percentage}%`}}</span><TippyIndicator /></span></div> -->
                        <div v-for="(item, index) in sector_benefits" :key="index" :class="['insight', { blur: !valid_recommendations && index !== 0 }]"><span v-html="`<b>${index + 1}.</b> ${$t(`benefits.long.${item.key}`)}`"/></div>
                        <div v-if="sector_benefits.length === 0" class="insight"><span>{{ $t('recommendations.data_not_available') }}</span></div>
                    </div>
                </div>

                <div v-else class="card loading"></div>

                <span v-if="valid_recommendations !== null && !valid_recommendations" class="unlock">{{$t('recommendations.benefits.card.unlock')}}</span>

                <div class="footnotes row nopadding-left">
                    <p v-html="$t('recommendations.real_time')"></p>
                    <button v-if="type === 'onboarding'" :class="['cta cta-slim cta-outline dark max-width']" @click="$emit('cancel')">{{$t('language.close')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'BenefitsCard',

    props: {
        classification: {
            type: Object,
            default() {
                return null
            }
        },

        valid_recommendations: {
            type: Boolean,
            default() {
                return false
            }
        },

        id: {
            type: String,
            default() {
                return ''
            }
        },

        type: {
            type: String,
            default() {
                return ''
            }
        }
    },

    data() {
        return {
            sector_benefits: [],
            loaded: false
        }
    },

    watch: {
        sector: function () {
            const self = this;
            if(self.sector || self.type === 'onboarding') {
                self.get_benefits_for_sector();
            }else {
                self.sector_benefits = [];
            }
        },

        valid_recommendations: function() {
            const self = this;
            if(self.sector || self.type === 'onboarding') {
                self.get_benefits_for_sector();
            }
        }
    },

    computed: {
        condition: function() {
            const self = this;
            if(self.type === 'onboarding') { return true }
            else { return self.job_class && self.valid_recommendations !== null }
        },

        sector: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].sector;
            }

            return null;
        },

        job_class: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].code;
            }

            return null;
        },

        job_key: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].key
            }

            return null;
        },

        job_type: function() {
            const self = this;

            if(self.classification && self.classification.interests && self.classification.interests.length !== 0) {
                return self.classification.interests[0].type;
            }

            return null;
        }
    },

    methods: {
        get_benefits_for_sector: function() {
            const self = this;

            const prefix = self.sector ? self.sector : '0';
            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_RECOMMENDATIONS_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_RECOMMENDATIONS_API_KEY,
                    'type': self.type,
                    'id': self.id
                }
            })

            instance.get(`benefits/sector/${prefix}`)
                .then(response => {
                    if(response.status == 200) {
                        self.sector_benefits = response.data;
                    }

                    self.loaded = true;
                })
                .catch(error => {
                    self.loaded = true;
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },
    },

    mounted() {
        const self = this;
        if(self.sector || self.type === 'onboarding') { self.get_benefits_for_sector(); }
    }
}
</script>
