<template>
    <div :class="[`classification client-${client}`]" v-if="initialized">
        <div class="search-box" v-if="!readonly">
            <p v-if="client === 'worker'" class="title">{{ $t('workerOffer.card.create.classification.search.title') }}</p>
            <p v-if="client === 'work-provider'" class="title">{{ $t('workProviderOffer.paid.card.classification.search.title') }}</p>
            <p v-if="client === 'work-provider_onboarding'" class="title">{{ $t('getstarted.steps.two.workProvider.splits.three.classification.title') }}</p>
            
            <div class="row" v-if="client === 'worker'">
                <p class="subtitle">{{ $t('workerOffer.card.create.classification.search.results.subtitle') }}</p>
                <p id="result-length" class="subtitle nowrap" v-if="query_results.length !== 0" v-html="$t('workerOffer.card.create.classification.search.results.number', { number: query_results.length })"></p>
            </div>

            <div class="row" v-else-if="client === 'work-provider_onboarding'">
                <p class="subtitle">{{ $t('getstarted.steps.two.workProvider.splits.three.classification.subtitle') }}</p>
                <p id="result-length" class="subtitle nowrap" v-if="query_results.length !== 0" v-html="$t('workerOffer.card.create.classification.search.results.number', { number: query_results.length })"></p>
            </div>

            <div class="row" v-else>
                <p class="subtitle">{{ $t('workProviderOffer.paid.card.classification.search.subtitle') }}</p>
                <p id="result-length" class="subtitle nowrap" v-if="query_results.length !== 0" v-html="$t('workerOffer.card.create.classification.search.results.number', { number: query_results.length })"></p>
            </div>
            
            <input id="input-search-box" type="text" :class="['input input-text search', error('interests', query_results.length !== 0), { loading, readonly }]" v-model="data.classification.query" :placeholder="$t('workerOffer.card.create.classification.search.placeholder')">
            <span class="notice" v-if="data.classification.query && data.classification.query.trim().length < 3">{{ $t('workerOffer.card.create.classification.search.notice') }}</span>
            <span class="notice error" v-if="search_error">{{ $t('workerOffer.card.create.classification.search.error') }}</span>
        </div>

        <FormError v-if="!readonly && query_results.length === 0" :data="validation.classification.interests" />

        <div class="search-results">
            <div class="selected-results" v-if="data.classification.interests && data.classification.interests.length !== 0">
                <div :id="`${result.code}-${result.key}`" :class="['result', { readonly }]" v-for="result in data.classification.interests" :key="result._id" @click="select_result(result, true)">
                    <p class="label" v-html="format_selected_result_label(result.label)"></p>
                    <font-awesome-icon :icon="['fa', 'xmark']" />
                </div>
            </div>
            
            <div v-if="data.classification.query && data.classification.query.trim().length >= 3 && !readonly" :class="['results', error('interests')]">
                <div v-if="query_results.length !== 0">
                    <div :id="`${result.code}-${result.key}`" :class="['result', { readonly }]" v-for="result in filter_query_results(query_results)" :key="result._id" @click="select_result(result, false)">
                        <p class="label" v-html="result.formatted_label"></p>
                        <span class="sector">{{ $t(`sectors.code.${result.sector}`) }}</span>
                    </div>
                </div>

                <div v-if="query_results.length === 0" class="result empty">
                    <p class="label">{{ $t('workerOffer.card.create.classification.search.results.empty') }}</p>
                </div>
            </div>

            <FormError v-if="!readonly && query_results.length !== 0" :data="validation.classification.interests" />
        </div>

        <p class="disclaimer">{{$t('language.masculin')}}</p>

        <div v-if="client === 'work-provider'" class="question experience column">
            <div class="label-container">
                <p>{{$t('workProviderOffer.paid.card.experienceLevels.title')}}</p>
                <p class="subtitle">{{$t('workProviderOffer.paid.card.experienceLevels.subtitle')}}</p>
            </div>

            <div class="checkbox-container">
                <div v-for="level in ['none', 'experienced', 'expert']" :key="level" :class="['checkbox-with-label', { readonly }]" @click="selectExperienceLevel(level)">
                    <input type="checkbox" :class="['input', error('experienceLevels', true), { readonly }]" :checked="data.classification.experienceLevels.includes(level)">
                    <p class="label">{{$t(`workProviderOffer.paid.card.experienceLevels.choices.${level}`)}}</p>
                </div>
            </div>

            <FormError v-if="!readonly" :data="validation.classification.experienceLevels" />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

import FormError from '../Utils/FormError.vue';

export default {
    name: 'ClassificationComponent',

    components: {
        FormError
    },

    props: {
        classification: {
            type: Object,
            default() {
                return {}
            }
        },

        salaries: {
            type: Array,
            default() {
                return []
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },

        validation: {
            type: Object,
            default() {
                return {}
            }
        },

        client: {
            type: String,
            default() {
                return 'worker'
            }
        }
    },

    watch: {
        validation: {
            deep: true,
            handler(val) {
                if(self.initialized) {
                    const self = this;
                    self.data.validation = val;
                }
            }
        },

        data: {
            deep: true,
            handler() {
                const self = this;
                if(self.initialized) {
                    self.$emit('update', self.data)
                }   
            }
        },

        'data.classification.query': function(query) {
            const self = this;
            const formatted = query.trim();

            if(self.initialized) {
                if(formatted.length > 0 && self.data.validation.classification.interests.error === true) {
                    self.data.validation.classification.interests.error = false;
                }

                if(formatted.length >= 3) {
                    self.throttle_search(formatted);
                } else {
                    self.query_results = [];
                }
            }
            
        }
    },

    data() {
        return {
            initialized: false,
            
            query_results: [],
            empty: false,

            data: {
                classification: {
                    experienceLevel: '',
                    interests: [],
                    query: null
                },
            },

            experience_level_options: ['none', 'experienced', 'expert'],

            loading: false,
            search_error: false
        }
    },

    computed: {
        ...mapGetters([
            'currentLanguage',
        ]),
    },

    methods: {
        search: function(input) {
            const self = this;

            self.loading = true;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_SEARCH_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_SEARCH_API_KEY,
                }
            })

            instance.get(`search/${self.currentLanguage}?q=${input}`)
                .then(response => {
                    self.loading = false;

                    if(response.status == 200) {
                        self.query_results = response.data;
                        self.search_error = false;
                    }else { self.search_error = true; }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }

                    self.loading = false;
                    self.search_error = true;
                });
        },

        get_info: function() {
            const self = this;

            const instance = self.$axios.create({
                baseURL: process.env.VUE_APP_SEARCH_API_ENDPOINT,
                headers: { 
                    'x-api-key': process.env.VUE_APP_SEARCH_API_KEY,
                }
            })
            
            let query = '';
            self.data.classification.interests.forEach((interest, index) => {
                if(index === self.data.classification.interests.length - 1) {
                    query = query += `${interest.type}_${interest._id}`
                }else {
                    query = query += `${interest.type}_${interest._id},`
                }
            })

            instance.get(`info/${self.currentLanguage}?q=${query}`)
                .then(response => {
                    if(response.status == 200) {
                        for (let index = 0; index < self.data.classification.interests.length; index++) {
                            const interest = self.data.classification.interests[index];
                            const result = response.data.find(result => result.id.toString() === interest._id.toString())

                            if(result) {
                                interest.label = result.label;
                                interest.formatted_label = result.formatted_label;
                            }
                        }

                        self.$nextTick(() => {
                            self.initialized = true;
                        })
                    }
                })
                .catch(error => {
                    if(error && error.response) {
                        if(error.response.status !== 400) {
                                console.log(JSON.stringify(error.response, null, 4))
                            }
                    } else { console.log(error); }
                });
        },

        throttle_search: _.throttle(function(query) {
            this.search(query, false);
        }, 500),

        select_result: function(result, remove) {
            const self = this;
            if(self.readonly) { return; }

            const index = self.data.classification.interests.map(selected_result => selected_result._id.toString()).indexOf(result._id.toString());
            if(index === -1) {
                if(self.client !== 'work-provider') {
                    self.data.classification.interests.push(result);
                    if(self.data.validation.classification.interests.error === true) { self.data.validation.classification.interests.error = false; }
                }else {
                    self.data.classification.interests = [result];
                }
            }else if (index !== -1 && remove) {
                self.data.classification.interests.splice(index, 1);
            }


        },

        format_selected_result_label: function(input) {
            if(input.length > 35) {
                return input.substring(0, 35) + '...'
            }else {
                return input;
            }
        },

        selectExperienceLevel: function(level) {
            const self = this;

            if(self.data.classification.experienceLevels.includes(level)) {
                if(self.data.classification.experienceLevels.indexOf(level) !== -1) {
                    self.data.classification.experienceLevels.splice(self.data.classification.experienceLevels.indexOf(level), 1);

                    if(self.data.salaries) {
                        self.data.salaries = self.data.salaries.filter(obj => {
                            return obj.experienceLevel !== level;
                        });
                    }
                }
            }else {
                self.data.classification.experienceLevels.push(level)
                if(self.data.salaries) {
                    self.data.salaries.push({
                        type: 'hourly',
                        amount: self.$CONSTANTS.MINIMUM_SALARY,
                        experienceLevel: level
                    })

                    self.$utils._re_order_salaries(self.data.salaries);
                }
            }
        },
        
        error: function(key, override) {
            try {
                if(!this.readonly && !override) return { error: this.validation.classification[key].error }
            }catch (e) {
                console.log(`${this.$options.name}: validation error for ${key}`)
            }
        },

        filter_query_results: function(query_results) {
            const self = this;
            return query_results.filter(result => !self.classification.interests.map(interest => interest._id.toString()).includes(result._id.toString()))
        }
    },

    mounted() {
        const self = this;

        if(self.classification && self.validation) {
            self.data = {
                classification: self.classification,
                validation: self.validation,
                salaries: self.salaries,
            };

            if(self.data.classification.interests.length !== 0) {
                if(self.data.classification.query && self.data.classification.query !== '') {
                    self.search(self.classification.query, true);
                }

                self.get_info();
            }else {
                self.$nextTick(() => {
                    self.initialized = true;
                })
            }   
        }
    }
}
</script>

