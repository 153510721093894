<template>
    <span :class="['tt tt-indicator', classes]"><img src="../../assets/images/icons/tooltip.svg" alt="tooltip"></span>
</template>

<script>

export default {
    name: "TippyIndicator",

    props: {
        classes: {
            type: Object,
            default() {
                return {};
            }
        }
    }
};
</script>