<template>
    <div v-if="loaded" class="question work-days column">
        <div class="label-container">
            <div class="under-title" v-if="client === 'worker'">
                <span class="header">{{ $t('work_days_under_title.header') }}</span>
                <span class="options" v-html="$t('work_days_under_title.option_1')"></span>
            </div>

            <p v-if="client === 'workProvider'">{{$t('workProviderOffer.shared.card.workDays.title')}}</p>
            <p v-else>{{$t('workDaysLabel')}}</p>
            <p class="subtitle">{{$t('getstarted.choose.many.female')}}</p>
        </div>

        <div class="button-group">
            <button id="work-day-monday" :class="['cta cta-option', error(), { active: data.workDays.includes('monday') }, { 'read-only': readonly }]" @click="select_work_day('monday')">{{$t('monday')}}</button>
            <button id="work-day-tuesday" :class="['cta cta-option', error(), { active: data.workDays.includes('tuesday') }, { 'read-only': readonly }]" @click="select_work_day('tuesday')">{{$t('tuesday')}}</button>
            <button id="work-day-wednesday" :class="['cta cta-option', error(), { active: data.workDays.includes('wednesday') }, { 'read-only': readonly }]" @click="select_work_day('wednesday')">{{$t('wednesday')}}</button>
            <button id="work-day-thursday" :class="['cta cta-option', error(), { active: data.workDays.includes('thursday') }, { 'read-only': readonly }]" @click="select_work_day('thursday')">{{$t('thursday')}}</button>
            <button id="work-day-friday" :class="['cta cta-option', error(), { active: data.workDays.includes('friday') }, { 'read-only': readonly }]" @click="select_work_day('friday')">{{$t('friday')}}</button>
            <button id="work-day-saturday" :class="['cta cta-option', error(), { active: data.workDays.includes('saturday') }, { 'read-only': readonly }]" @click="select_work_day('saturday')">{{$t('saturday')}}</button>
            <button id="work-day-sunday" :class="['cta cta-option', error(), { active: data.workDays.includes('sunday') }, { 'read-only': readonly }]" @click="select_work_day('sunday')">{{$t('sunday')}}</button>
        </div>

        <FormError v-if="!readonly" :data="validation.workDays" />
    </div>
</template>

<script>
import FormError from '../../components/Utils/FormError.vue'

export default {
    name: 'WorkDaysComponent',

    components: { FormError },

    props: {
        workDays: {
            type: Array,
            default() {
                return []
            }
        },

        workTimes: {
            type: Array,
            default() {
                return []
            }
        },

        readonly: {
            type: Boolean,
            default: false
        },

        client: {
            type: String,
            default: 'worker'
        },

        validation: {
            type: Object,
            default() {
                return {}
            }
        }
    },

    watch: {
        workDays: function(val) {
            this.data.workDays = val;
        },
        
        validation: {
            deep: true,
            handler(val) {
                const self = this;
                self.data.validation = val;
            }
        }
    },

    data() {
        return {
            data: {
                workDays: [],
                workTimes: [],
                validation: {}
            },

            loaded: false
        }
    },

    methods: {
        error: function() {
            try {
                if(!this.readonly) return { error: this.validation.workDays.error }
            } catch (e) {
                console.log(`${this.$options.name}: validation error`)
            }
        },

        select_work_day: function(day) {
            const self = this;

            if(self.data.workDays.includes(day)) {
                if(self.data.workDays.indexOf(day) !== -1)
                    self.data.workDays.splice(self.data.workDays.indexOf(day), 1);

                self.data.workTimes = self.data.workTimes.filter(obj => {
                    return obj.day !== day;
                });
            } else {
                self.data.workDays.push(day);
                self.data.workTimes.push({
                    day,
                    active: true,
                    timeSlots: [{
                        start: {
                            value: '',
                        },
                        end: {
                            value: '',
                        }
                    }],
                });

                self.data.validation.workTimes[day].start.error = false;
                self.data.validation.workTimes[day].end.error = false;

                self.$utils._re_order_work_times(self.data.workTimes);
            }
            
            self.$utils._re_order_work_days(self.data.workDays);
            
            if(self.data.workDays.length !== 0 && self.data.validation.workDays.error === true) {
                self.data.validation.workDays.error = false;
            }

            self.$emit('update', self.data);
        }
    },

    mounted() {
        const self = this;

        if(self.workDays && self.workTimes && self.validation) {
            self.data = {
                workDays: self.workDays,
                workTimes: self.workTimes,
                validation: self.validation
            };

            self.$nextTick(() => {
                self.loaded = true;
            })
        }
    }
}
</script>

